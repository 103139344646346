
import React from 'react';
import './ComingSoon.css';

export default function ComingSoon() {
 return (
  <div className="coming-soon">
   <h1>Secrets Make Friends is undergoing changes, come back later!</h1>
  </div>
 )
}